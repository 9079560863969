import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import cn from 'classnames'

function SortArrow({ direction }: { direction: 'ASC' | 'DESC' }) {
  return (
    <div
      className={cn(
        'sortArrow',
        'tw-transform-90',
        direction === 'ASC' && ['tw-ml-1', '-tw-rotate-90'],
      )}
    >
      <div className={cn('tw-w-[18px]', 'tw-h-[18px]')}>
        <Icon icon={Icons.Arrow} />
      </div>
    </div>
  )
}

export default SortArrow
