import React, { useState, Fragment } from 'react'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import TableDrawer from './TableDrawer'
import styles from './Row.module.css'
import classNamesBind from 'classnames/bind'
const cn = classNamesBind.bind(styles)

function Row({
  data,
  columns,
  Drawer,
  onClick,
}: {
  data: any
  columns: any
  Drawer?: React.ElementType | null
  onClick?: (data: any) => void
}) {
  const [open, setOpen] = useState(false)

  const resolvePath = (data = {}, path?: any[]) => {
    if (!path) return data
    return path.reduce((acc, key) => acc && acc[key], data)
  }

  return (
    <Fragment>
      <tr
        onClick={onClick ? () => onClick(data) : undefined}
        className={cn('body-row', { 'tw-cursor-pointer': !!onClick })}
      >
        {columns.map((column: any) => {
          const resolvedData = resolvePath(data, column.path)
          if (column.key === 'options') return null
          return (
            <Fragment key={column.key}>
              {column?.transform ? (
                column.transform(resolvedData)
              ) : (
                <td
                  className={cn(
                    'body-data',
                    'tw-font-normal',
                    'tw-text-sm',
                    'tw-leading-[1.40625]',
                    'tw-max-w-[6em]',
                    'tw-mr-4',
                    'tw-py-3',
                    'tw-relative',
                    'first:tw-pl-4',
                    'last:-tw-right-4',
                    column.className,
                  )}
                >
                  <div
                    className={cn(
                      'tw-whitespace-nowrap',
                      'tw-overflow-hidden',
                      'tw-text-ellipsis',
                      'tw-block',
                      'tw-w-auto',
                      'tw-h-fit',
                      'tw-pr-6',
                    )}
                  >
                    {resolvedData || '-'}
                  </div>
                </td>
              )}
            </Fragment>
          )
        })}

        {Drawer && (
          <td
            className={cn(
              'tw-w-16',
              'tw-text-center',
              'tw-mr-4',
              'tw-py-3',
              'tw-relative',
              'first:tw-pl-4',
              'last:-tw-right-4',
            )}
            onClick={e => {
              e.stopPropagation()
              setOpen(!open)
            }}
          >
            <Icon
              className={cn(
                'tw-w-6',
                'tw-h-6',
                'tw-inline-block',
                'tw-m-auto',
                'tw-border',
                'tw-border-transparent',
                'tw-rounded',
                'hover:cukrsor-pointer',
                'hover:border-gray-300',
                'hover:bg-gray-200',
              )}
              icon={Icons.Ellipsis}
            />
          </td>
        )}
      </tr>
      <TableDrawer show={open}>
        {Drawer && data && <Drawer data={data} />}
      </TableDrawer>
    </Fragment>
  )
}

export default Row
