import SortArrow from './SortArrow'
import cn from 'classnames'

function TableHeader({
  columns,
  sortCallback,
  getSortInfo,
}: {
  columns: any
  sortCallback?: any
  getSortInfo?: any
}) {
  return (
    <thead>
      <tr className={cn('header-row', 'tw-mb-[1.5rem]')}>
        {columns.map((column: any) => (
          <th
            className={cn(
              'table-header',
              'tw-text-secondary',
              'tw-uppercase',
              'tw-justify-center',
              'tw-text-center',
              'tw-cursor-pointer',
              'tw-font-bold',
              'tw-leading-[1.4]',
              'tw-bg-primary',
              'tw-mr-4',
              'tw-py-3',
              'tw-relative',
              'first:tw-pl-4',
              'last:-tw-right-4',
            )}
            key={column.key}
            style={column.customStyle ? column.customStyle : {}}
            onClick={() =>
              column.sortable && sortCallback && sortCallback(column.sortable)
            }
          >
            <div className={cn('tw-flex')}>
              {column.label}
              {column.sortable && (
                <SortArrow direction={getSortInfo(column.key).direction} />
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

export default TableHeader
