import LoadingBars from '@crystal-eyes/premeeting/components/elements/Loader/LoadingBars'
import cn from 'classnames'

function EmptyState({
  loading,
  length,
  toggleAddProfilesModal,
  CustomEmptyState = null,
}: {
  loading: boolean
  length: number
  toggleAddProfilesModal: () => void
  CustomEmptyState?: React.ElementType | null
}) {
  return (
    <tr className={cn('tw-min-h-[300px]')}>
      <td className={cn('tw-pr-4')} colSpan={length}>
        {loading || typeof loading == 'undefined' ? (
          <LoadingBars count={10} loading={loading} />
        ) : CustomEmptyState ? (
          <CustomEmptyState />
        ) : (
          <div
            className={cn(
              'tw-text-sm',
              'tw-text-[#6c6c6c]',
              'tw-font-light',
              'tw-italic',
              'tw-mt-1.5',
              'tw-leading-[140%]',
              'tw-text-center',
            )}
          >
            No profiles to display.{' '}
            <span
              className={cn(
                'tw-cursor-pointer',
                'tw-text-[#1e749f]',
                'tw-text-sm',
                'tw-font-normal',
                'tw-leading-[140%]',
                'tw-underline',
              )}
              onClick={() => toggleAddProfilesModal()}
            >
              Add a new profile to your library
            </span>
          </div>
        )}
      </td>
    </tr>
  )
}

export default EmptyState
