import classNamesBind from 'classnames/bind'
import css from './Main.module.css'
const cn = classNamesBind.bind(css)

interface OwnProps {
  children: React.ReactNode
}

export default function Main({ children }: OwnProps) {
  return (
    <table
      className={cn(
        'tableContainer',
        'tw-w-full',
        'tw-bg-primary',
        'tw-max-w-[1040px]',
      )}
    >
      {children}
    </table>
  )
}
