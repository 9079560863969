import { useState, useEffect } from 'react'
import styles from './Search.module.css'
import Image from 'next/image'
import searchIcon from '@crystal-eyes/resources/images/search.svg'
import filterIcon from '@crystal-eyes/resources/images/filter.svg'
import classNamesBind from 'classnames/bind'
import { useDebounce } from '@crystal-eyes/hooks/useDebounce'

const cn = classNamesBind.bind(styles)

function TableSearch({
  handleSearch,
  type,
  className,
}: {
  handleSearch: (searchQuery: string) => void
  type: 'SEARCH' | 'FILTER'
  className?: string
}) {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedHandleSearch = useDebounce(handleSearch, 500)

  useEffect(() => {
    debouncedHandleSearch(searchQuery)
  }, [searchQuery])

  const placeholder = type === 'SEARCH' ? 'Search' : 'Filter'
  const icon = type === 'SEARCH' ? searchIcon : filterIcon

  return (
    <div
      className={cn(
        'searchArea',
        'tw-relative',
        'tw-h-[1.28px]',
        'tw-px-[8px]',
        'tw-py-[11px]',
        'tw-leading-4',
        'tw-font-normal',
        'tw-text-lg',
        'tw-whitespace-nowrap',
        'tw-overflow-hidden',
        'tw-text-ellipsis',
        'tw-box-content',
        className,
      )}
    >
      <input
        className={cn(
          'searchInput',
          'tw-absolute',
          'placeholder:tw-text-[0.9rem]',
          'placeholder:tw-tracking-wide',
          'tw-pt-[2px]',
        )}
        placeholder={placeholder}
        value={searchQuery}
        onChange={evt => setSearchQuery(evt.target.value)}
      />
      <Image
        className={cn('tw-absolute', 'tw-right-[8px]', 'tw-top-[15px]')}
        src={icon as any as string}
        width={13}
        height={13}
        alt={placeholder}
      />
    </div>
  )
}

export default TableSearch
